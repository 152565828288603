
#header {
    display: flex;
    background-color: #18B773;
    width: 100%;
    height: 55px;
    box-shadow: 0px 2px 5px rgba(99, 182, 43, 0.25);
    align-items: center;
    justify-content: center;
    
    
  }
.ul_header{
    display:flex;
    float: right;
    align-items: center;
    list-style: none;
    
}
article{
  flex: 1;
}

