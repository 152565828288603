.divCadastroMaquinas{
  display: flex;
  }
body{
  background-color: #EEF5F9;
}
.BotaoVoltar_Maquinas{
    display: flex;
    position: absolute;
    margin-right: 91%;
    margin-top: 15px;
    background-color: transparent;
}
  
  .formGroup {
  flex-direction: column;
  display: flex;
  }
  
 
  .colorBackgroundCadastroMaquinas{
  font-family: sans-serif;
  background-color: #EEF5F9;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  }
  
.inputPadrao_CreateMachine{
  width: 95;
  margin-left: 3px;
  margin-bottom: 5px;
  text-shadow: none;
  text-align: start;
  height: 35px;
  border-radius: 5px;
  border: 1px solid gray;
  padding-left: 5px;
  font-size: 15px;
}

.inputPadrao_CreateMachineDescrip{
  width: 95;
  margin-left: 3px;
  margin-bottom: 5px;
  text-shadow: none;
  text-align: start;
  height: 35px;
  border-radius: 5px;
  border: 1px solid gray;
  padding-left: 5px;
  font-size: 15px;
}
  
  .formMachineCadastro{
  border: 1px solid #ffffff;
  padding: 10px;
  width: 400px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px 10px 0 0;
  }
  

.Cadastrar-center{
  display: flex;
  justify-content: center;
}
  .divContainerFormMachine{
    margin-top: 25px;
    font-family: sans-serif;
    
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 0 0;
    width: 400px;
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid green;
  }
  
  .formGroup__label{
  margin-top: 13px;
  }
  
  .buttonCadastroMaquinas{
  font-family: sans-serif;
  display: flex;
  margin-left: 150px;
  margin-top: 15px ;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100px;
  height: 40px;
  color: white;
  font-weight: bolder;
  font-size: 18px;
  background-color: #18b773;
  border: 1px solid #18b773;
  cursor: pointer;
  }

body{
  background-color: #EEF5F9;

  }

.error-message_maquina{
  color: red;
  margin-left: 35px;
}

.p_color{
  color: red;
  display: flex;
  margin-left: 4px;


}