
.nav_painel{
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    width: 98;
    height: 55px;
    color: #197ED6;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  }


.titulo_painel_administracao{
    margin-left: 45px;
    font-size: 22px;
}

.div-administração {
  zoom:100%;
  width: 100%;
  display: flex;
  background-color: rgb(238, 245, 249);
}

.navEndereco_administracao{
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: lighter; 
}

.navHome_administracao{
  margin-right: 15px;
  cursor: pointer;
}
.conteudo_adm h2{
  font-family: sans-serif;
  margin-bottom: 5px;
}
.navAtual_administracao{
  color: #99abb4;
  margin-right: 20px;
}








.padding-administração{
  display: flex;
  justify-content: space-evenly;
  width: 98%;
  margin-top: 1%;
  margin-bottom: 20px;
  margin-left: 27px;
}


.containersup_adm{
  padding-bottom: 10px;
  color: #139b60;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 14px;
  width: 23%;
  margin-right: 12px;
  box-sizing: border-box; 
  margin-left: 2%;
  background-color: #ffffff;
  transition: 0.3s;
}

.containersup_adm:hover{
  scale: 1.05;
}

.cardNumber_adm{
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 60px;
  font-weight: 900;
  margin-top: 1%;
}

.txtCard_adm{
  font-weight: 600;
  font-family: sans-serif;
  text-align: center;
}
.dropdown-container-maquinas{
  margin-left: 60px;
  border:0 2px;
  display: flex;
  text-decoration: none;
  margin-top: 22px;
 
}
.width_select{
  width: 100px;
  height: 25px;
  border-radius: 2px;
  margin-top: 3px;
  border-color: #197ED6;
  background-color: aliceblue;
  
}
.text_adm{
  margin-top: -36px;
  margin-left: -9px;
  display: flex;

  
}
.text_adm2{
  margin-top: -30px;
  
}
.size_font{
  font-size: 5px;
}












.conteudo_adm{
  margin-left: 37px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-bottom: 35px;
  
}

.graf-adm{
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 350px;
  width: 45%;
  transition: transform 0.4s;

}
.graf-adm:hover {
  transform: scale(1.08); /* Amplia o elemento em 10% quando o mouse passa por cima */
}

