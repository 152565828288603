/* modal.css */

.modal_tec {
    /* Estilos para o modal em si */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0; /* Inicialmente invisível */
    transition: opacity 0.3s ease-in-out;
  }
  
.modal_tec.open {
    opacity: 1; /* Torna o modal visível quando aberto */
  }
  
  .modal-content_tec {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    height: 600px;
    text-align: center;
  }
  
  /* Estilos para o cabeçalho (h2) */
  .h2_content_tec {
    margin-top: 0; /* Remove o espaço superior padrão do h2 */
    font-size: 24px;
  }
  
  /* Estilos para os rótulos e inputs */
.label_modal_tec {
    display: block; /* Coloca os rótulos e inputs em uma nova linha */
    margin-bottom: 10px; /* Espaçamento entre os campos */
  }

.p_modal_tec{
    display: flex;
}
  
.input_modal_tec[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Estilos para os botões */
.button_modal_save_tec {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #cccdce;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .button_modal_save_tec:active{
    transform: translateY(2px);
  }
  
  .button_modal_cancel_tec:active {
    transform: translateY(2px);
  }

.button_modal_cancel_tec {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #cccdce;
    color: rgb(5, 5, 5);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
    width: 25%;
}
.error-message{
  color: red;
}
.p_modal_tecc{
  display: flex;
  margin-top: 18px;
  font-size: 18px;
  
}
.button_modal_cancel_tecc{
  margin-top: 10px;
 
    padding: 8px 16px;
    background-color: #cccdce;
    color: rgb(5, 5, 5);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 20px;
    margin-top: 160px;
    width: 40%;
  
   
}
.number_get_tec{
  color: rgb(83, 83, 83);
  margin-left: 5px;

}
.div_info_tec{
  margin-top: 44px;
  
}

.h2_content_tecc{
  margin-top: 22px;
  
}
.button_modal_cancel_tecc:hover{
  background-color: #a3a1a1;
  width: 50%;
}