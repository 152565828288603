.div-Maquinas{
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgb(238, 245, 249);
  overflow: auto;
  

 
  

}
.padding-Maquinas{
  margin-left: 18px;
   
}
.nav_Maquinas{
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  width: 98;
  height: 55px;
  color: #197ED6;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}
.titulo_Maquinas{
  margin-left: 32px;
  font-size: 22px;
}
.navEndereco_Maquinas{
  display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: lighter;
}
.home-redirect-Maquinas{
  color: #197ED6;
}
.navHome_Maquinas{
  margin-right: 15px;
    cursor: pointer;
}
.navAtual_Maquinas{
  color: #99abb4;
  margin-right: 20px;
}
.search-submit-container-Maquinas{
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
  margin-right: 3.2%;

 
  

}
.search-container-Maquinas{
  margin-left: 2.5%;
  width: 450px;
  height: 35px;
  border-width: 1px;
  border-radius: 5px;
  font-family: sans-serif;
  border-color: rgb(14, 14, 14, 0.2);
  box-sizing: border-box;
  background-image: url(../../components/img/searchIcon.svg);
  background-position: 5px 5px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  color: #494949;
  transition: width 0.4s ease-in-out;
  transition: box-shadow 0.4s ease-in-out;
  
}

.search-container-Maquinas:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgb(24,183,115, 0.75);
}

.botão-style-Maquinas{
  height: 40px;
  width: 100px;
  border-radius: 5px;
  border: none;
  align-items: center;
  font-weight: bolder;
  font-size: 18px;
  justify-content: center;
  color: white;
  background-color:#18b773;
  cursor: pointer;
  
}
.botão-style-Maquinas:active{
  transform: translateY(2px);
}
.div-tabela{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 98%;
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  
}
.table-style-Machine{
  padding: 5px;
    text-align: left;
    border-collapse: collapse;
    font-family: sans-serif;
    width: 96%;
    min-width: 400px;
    margin-top: 15px;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 40px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px 5px 0 0;
}
.table-style-Machine thead{
  background-color: #18B773;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
   
}

.table-style-Machine th,
.table-style-Machine td {
  padding: 12px 15px;
}
.table-style-Machine tbody tr{
  border-bottom: 1px solid #dddddd;
}
.table-style-Machine tbody tr:nth-of-type(even){
  background-color: #f3f3f3;
}
.table-style-Machine tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}


.table-body-Maquinas td{
  padding-bottom: 12px;
  padding-top: 12px;
  background-color: white;
  border-bottom: solid 1px rgb(136, 136, 136, 0.2);
  
}
.dropdown-container-Maquinas{
  position: relative;
  display: block;
  
}


.icon-settings {
  position: relative;
  top: 2px;
  
  
}
.dropbtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  
}
.dropdown-item {
  display: block;
  width: 90px;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

}

.dropdown-menu-EcoTV.show {
  display: block; 
}


.dropdown-item {
  background-color: #4CAF50; 
  color: white; 
  text-align: center; 
  text-decoration: none;
  display: block; 
  margin-bottom: 5px; 
  border-radius: 4px; 
  transition: background-color 0.3s;
  height: auto;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #45a049; 
}


.dropdown-item.delete-button {
  background-color: #f44336; 
}

.dropdown-item.delete-button:hover {
  background-color: #d32f2f; 
}

.dropdown-item:hover,
.dropdown-item:focus {
  scale: 1.1;
  color: #ffffff;
  background-color: #42ad47;
}
.nome-Maquinas{
  color: #197ED6;
  cursor: pointer;
}


.icon-add-machine{
  width: 25px;
  height: 55px;
  
  
}



.dropdown-menu-Maquinas {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  display: none;
  min-width: 120px;
  padding: 8px 0;
  margin: 2px 0 0;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.175);
}

.show {
  display: block;
}



.dropdown.show .dropdown-content-Maquinas {
  display: block;
}

.LogoImg-machine{
  margin-left: 35%;
    opacity: 0.1;
    height: 300px;
    width: 400px;
    animation:inherit 7s infinite;
}
.transparent-background {
  

 
  width: 100%;
  height: 100%;
  background-color: rgb(52, 108, 136);
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;


}


.opaque-table {
  opacity: 0.4; /* Define a opacidade desejada */
  pointer-events: none; /* Isso evita que os eventos passem pela tabela enquanto estiver opaca */
  
}


.checkbox-container {
  font-family: sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 12px 2.6%;
}

.checkbox-container label {
  margin-left: 8px;
  color: #197ED6; /* Cor do texto do label */
  font-size: 18px; /* Tamanho do texto */
}

.checkbox-container input[type="checkbox"] {
  width: 20px; /* Largura do checkbox */
  height: 20px; /* Altura do checkbox */
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 2px solid #000000;
  border-radius: 4px;
}

.checkbox-container input[type="checkbox"]:checked {
  background-color: #18B773;
}

.checkbox-container input[type="checkbox"]:checked:after {
  content: '\2714';
  color: white;
  position: absolute;
  transform: scale(1.5);
  left: 4px;
  top: -2px;
}
