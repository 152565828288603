.container-painel {
    display: flex;

}  
.wrapper {
    display: flex;


} 
.padding-localização{
    margin-left: 18px;

}
.div-localização{
    width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgb(238, 245, 249);
  overflow: auto;
}

.body_Localizacao{
    display: flex;
    justify-content: center;
    width: 100%;
    }

.mapCard_Localizacao{
    display: flex;
    align-items: center;

    flex-direction: column;
    margin-top: 15px;
    width: 80%;
    }



.mapCard_Localizacao p{
    font-family: sans-serif;
    margin-bottom: 15px;
    margin-top: 10px;
}




.mapCard_Localizacao p{
    font-family: sans-serif;
    margin-bottom: 15px;
    margin-top: 10px;
    font-weight: 600;
}
.leaflet-container{

    width: 60vw;
    height: 70vh;
  }


.selectMachine{
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #606060;
    border-radius: 3px;
    font-size: 12px;
    width: 160px; /* Ajuste a largura conforme necessário */
    outline: none;
}

.p{
    color: rgb(0, 162, 255);
}

.mapCard_Localizacao{
    margin-bottom: 1y5px;
}

.nav_Localizacao{
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  width: 98;
  height: 55px;
  color: #197ED6;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}

.titulo_Localizacao{
    margin-left: 32px;
    font-size: 22px;
  }

.navEndereco_Localizacao{
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: lighter;
}

.navHome_Localizacao{
    margin-right: 15px;
      cursor: pointer;
      color: #197ED6;
  }

.navAtual_Localizacao{
    color: #99abb4;
    margin-right: 20px;
}


