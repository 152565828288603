.app_Login_global {
  text-align: center;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(70deg, #00aa7e, #008b9d 70%);
  flex-direction: column;
}

.box_Login, .login-form {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  
  
 
}

.box_Login h2{
  margin-bottom: 30px ;
}

@media screen and (min-width: 600px) {
  .box_Login {
    background-color: rgb(255, 255, 255);
    opacity: 90%;
    padding: 2rem 2rem 2rem 2rem;
    border: 1px solid white ;
    border-radius: 15px;
    margin: 0.5rem;
  }
}

.login-form_label {
  text-align: left;
  padding: 7px 8px 8px 1px ;
  /* padding-bottom: 7px; */
  
}

.login-form_label_input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: 1px solid gray ;
  border-radius: 10px;
  width: 350px;
}

.login-form__log_in  {
  margin-top: 20px;
  
  border: 1px solid black ;
  background-color: #18B773;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}

.box_Login_register{
  margin-top: 20px;
  border: none;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  background: none;
  color: black;
  text-decoration: underline;

}
.box_Login_esqueceSenha{
  margin-top: 14px;
  border: none;
  cursor: pointer;
  background: none;
  color: black;
  text-decoration: underline;


}

#Img_EcomachineLog{
  width: 820px;
  height: 900px;
  background-color: red;
  display: flex;
  flex-direction: column;
}





@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.EcoMachLogin {
  color: transparent;
  background: linear-gradient(70deg, #06c491, #1affc2 70%);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: sans-serif;
  font-size: 35px;
  font-weight: bold;
  animation: gradientAnimation 5s ease infinite;
  background-size: 200% 200%;
}

@keyframes textFadeAndColorPulse {
  0%, 100% {
    color: transparent; /* Texto completamente invisível */
    opacity: 0;
  }
  
  50% {
    color: #0affbe; /* Tom de verde claro */
    opacity: 1;
  }
}

.RepenseLogin {
  font-family: sans-serif;
  font-size: 35px;
  font-weight: bold;
  animation: textFadeAndColorPulse 6s ease-in-out infinite;
}