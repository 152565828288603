.div{
    display: flex;
    
}  
.padding-EcoVideo{
    margin-left: 38px;
    
}
 .div-EcoVideo{
    display: flex;
    background-color: #EEF5F9;
 }

#BotaoVoltar_EcoVideo{
    display: flex;
    margin-right: 96%;
    margin-top: 15px;
}
.body_EcoVideo{
    font-family: sans-serif;
    background-color: #EEF5F9;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 94vh;

    
}

.VideoPlayer_EcoTV{
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
