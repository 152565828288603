/* modal.css */

.modal {
    /* Estilos para o modal em si */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0; /* Inicialmente invisível */
    transition: opacity 0.3s ease-in-out;
  }
  
  .modal.open {
    opacity: 1; /* Torna o modal visível quando aberto */
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    height: 540px;
    text-align: center;
  }
  
  /* Estilos para o cabeçalho (h2) */
  .h2_content {
    margin-top: 0; /* Remove o espaço superior padrão do h2 */
    font-size: 24px;
  }
  
  /* Estilos para os rótulos e inputs */
.label_modal {
    display: block; /* Coloca os rótulos e inputs em uma nova linha */
    margin-bottom: 10px; /* Espaçamento entre os campos */
  }

.p_modal{
    display: flex;
}


.input_modal {
  width: 100%; /* Largura completa */
  padding: 8px; /* Espaçamento interno */
  margin-top: 5px; /* Espaço acima */
  border: 1px solid #ccc; /* Borda cinza */
  border-radius: 4px; /* Bordas arredondadas */
  font-size: 16px; /* Tamanho da fonte */
  box-sizing: border-box; /* Para incluir padding e border na largura total */
}


.input_modal[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Estilos para os botões */
.button_modal_save {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #cccdce;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
.button_modal_cancel {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #cccdce;
    color: rgb(5, 5, 5);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 33px;
}
  