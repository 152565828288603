*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}
.sidebar{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    background: #fdfdfd;
    color: #5a5a5a;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    transition: 0.2s ease-in-out;
    
}
.sidebar__top-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;

}
.logo{
    font-size: 18px;
    color: mediumspringgreen;
    line-height: 0;
    
}

.sidebar-item__link:hover{
    border-right: 2px solid rgb(85, 85, 85);
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    background: rgba(229, 209, 209, 0.199);
}



.sidebar__logo{
    height: 20px;
    display: flex;
    align-content: center;
    cursor: pointer;
    
}

.container-main{
    display: flex;
}
a{
    text-decoration: none;
}
.sidebar__bars{
    margin-top: 8px;
    width: 15px;
    cursor: pointer;
}
.sidebar-nav {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    
}
  
.sidebar-item {
    color: rgb(0, 0, 0);
    transition: all 0.2s ease;
    margin-bottom: 30px; /* adiciona espaçamento entre os itens */
    align-self:flex-start;
}

.sidebar-item.sidebar-item-closed {
    font-size: 1.9rem;
    padding: 0rem 0.5rem;
}
.sidebar-item__icon {    
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    color: #727272;
    margin-left: 15px;
   
}


.sidebar.sidebar-item-closed .sidebar-item__link {
    display: none;
}
  
.sidebar.sidebar-item-closed .sidebar-item__icon {
    margin-right: 0;
    font-size: 25px;
}
.sidebar-item-closed .sidebar-item__text {
    display: none;
}
.sidebar-item__link-active {
    background: #ffffff48;
    padding-top: 55px;
  
}

.sidebar.open {
  width: 200px;
}

.content.open {
    padding-left: 200px;
    transition: padding 0.2s ease-in-out;
    margin-left: -20px;
}
.content:not(open){
    transition:padding 0.2s ease-in-out;
    margin-left: -5px;
    
}
.sidebar.sidebar-item-closed.sidebar-item__link:hover{
    display: none;
}
.sidebar-item__icon.active{
    margin-right: 5px;
    background: #a4c5a1;
    
}
.sidebar-item__icon-closed{
    margin-left: -14px;
}
.sidebar-item__text-active{
    background-color: rgb(175, 18, 18);
}


.sidebar-indicator{
    color: red;
}
.icon-container {
    position: relative;
    display: inline-flex; /* Garante que o ícone e o indicador fiquem alinhados */
  }
  .chamada-ativa-indicador {
    position: absolute;
    top: 0; /* Ajuste conforme necessário para posicionar corretamente */
    right: 0;
    left: 5; /* Ajuste conforme necessário */
    background-color: red;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 14px;
    border: 2px solid white; /* Adiciona uma borda para destacar o indicador */
  }