.div-painel
.padding-painel{
    margin-left: 18px;
    font-family: sans-serif;
  }

  .nav_painel{
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    width: 98;
    height: 55px;
    color: #197ED6;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .navEndereco_painel{
      display: flex;
      flex-direction: row;
      font-size: 14px;
      font-weight: lighter;
  }
  
  .titulo_painel{
      margin-left: 32px;
      font-size: 22px;
  }
  
  .navHome_painel{
      margin-right: 15px;
      cursor: pointer;
  }
  
  .navAtual_painel{
      color: #99abb4;
      margin-right: 20px;
  }
  
.div-painel{
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgb(238, 245, 249);
  overflow: auto;
}

.Tabsuperior_painel{
  display: flex;
  width: 100%;
  margin-top: 1%;
  
}

.containersup_painel{
  padding-bottom: 10px;
  color: #139b60;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 14px;
  width: 23%;
  margin-right: 12px;
  box-sizing: border-box; 
  margin-left: 2%;
  background-color: #ffffff;
  transition: 0.3s;
}

.containersup_painel:hover{
  scale: 1.05;
}
.cardNumber_painel{
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 60px;
  font-weight: 900;
  margin-top: 1%;
}

.submaqativas_painel{
  font-weight: 600;
  font-family: sans-serif;
  text-align: center;
}


  
.submaqcheias_painel{
  font-weight: 600;
  font-family: sans-serif;
  text-align: center;
}


.subalarmativas_painel{
  font-weight: 600;
  font-family: sans-serif;
  text-align: center;
}



.subchmativas_painel{
  font-weight: 600;
  font-family: sans-serif;
  text-align: center;
}






.table_painelStyle{
  max-width: 99% ;
  margin-top: 5px;
}
.header_painel{
  display: table-header-group;
  border-collapse: collapse;
}
.Tabinferior_painel{
  margin-left: 19;
  margin-top: 25px;
  display: flex;
  justify-content: space-evenly;
 
}

.table_painelStyle thead th{
  background-color: #18B773;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.table_painelStyle th,
.table_painelStyle td {
  padding: 12px 15px;
}

.table_painelStyle tbody tr {
  border-bottom: 1px solid #dddddd;
}


.table_painelStyle tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}


.table_painelStyle tbody tr:nth-of-type(even) {
  background-color: #f0fff0;
}
.table_painelStyle tbody tr:nth-of-type(odd) {
  background-color: #f6fcf9;
}

.graf_fun_painel{
  padding-top: 5px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 400px;
  width: 45%;
  transition: transform 0.4s;
}

.graf_fun_painel_alarm:hover {
  transform: scale(1.09); /* Amplia o elemento em 10% quando o mouse passa por cima */
}
.graf_fun_painel_alarm{
  padding-top: 5px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 400px;
  width: 45%;
  transition: transform 0.4s;
  overflow: auto;
}

.graf_fun_painel:hover {
  transform: scale(1.09); /* Amplia o elemento em 10% quando o mouse passa por cima */
}

.TitlePizzaPainel{
  margin-top: 40px;
}