/* recovery.css */



.recovery_global {
    background-image: linear-gradient(70deg, #00aa7e, #008b9d 70%);
    font-family: 'Arial', sans-serif;
    color: #fffffe;
    margin: 0;
    padding: 0;
  }

.recovery_text{
    display: flex;
    
}
  
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  
}
  
.title {
    color: #abd1c6;
}
  
.form {

    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    height: 240px;
    width: 370px; /* Fixed width or max-width for better control */
}
  
.form-group {
    margin-bottom: 15px;
}
  
.label {
    display: block;
    margin-bottom: 5px;
    color: #004643;
}
  
.input {
    width: calc(100% - 20px); 
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
.button {
    width: calc(100% - 20px); /* Adjust width to account for padding */
    padding: 10px;
    background-color: #83c5be;
    border: none;
    border-radius: 4px;
    color: #fffffe;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
.button:hover {
    background-color: #2b5a33;
}
  
.message {
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px;
    border-radius: 4px;
}


.info-text {
    font-size: 0.9em; /* Adjust the font size as needed */
    color: #616060; /* A softer color for the informative text */
    margin-bottom: 20px; /* Spacing before the button */
    font-family: 'Roboto', sans-serif;
    text-align: left; /* Align text to the left */
    font-size: 18px;

}


.strong_color{
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}