.container-painel {
    display: flex;
}  
.wrapper {
    display: flex;


} 
.padding-tecnicos{
    margin-left: 18px;
}
.div-tecnicos{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: rgb(238, 245, 249);
    overflow: auto;
}

.nav_Tecnicos{
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    width: 98;
    height: 55px;
    color: #197ED6;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}

.titulo_Tecnicos{
    margin-left: 32px;
    font-size: 22px;
}

.navEndereco_Tecnicos{
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: lighter; 
}

.navHome_Tecnicos{
    margin-right: 15px;
    cursor: pointer;
}

.navAtual_Tecnicos{
    color: #99abb4;
    margin-right: 20px;
}

.navAtual_Tecnicos{
    color: #99abb4;
    margin-right: 20px;
}

.pListaDeTec{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #eef5f9;
}
.dropdown-item.delete-button {
    background-color: #f44336; 
  }
  


  .dropdown-item1 {
    background-color: #4CAF50; 
    color: white; 
    text-align: center; 
    text-decoration: none;
    display: block; 
    margin-bottom: 5px; 
    border-radius: 4px; 
    transition: background-color 0.3s;
    width: 93px;
    border: none;
  }
.pListaDeTec p{
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 25px;
    color: #4380A8;
}

.topCard_Tecnicos{
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.Pesq_Tecnicos{
    margin-left: 2.5%;
    width: 450px;
    height: 35px;
    border-width: 1px;
    border-radius: 5px;
    font-family: sans-serif;
    border-color: rgb(14, 14, 14, 0.2);
    box-sizing: border-box;
    background-image: url(../../components/img/searchIcon.svg);
    background-position: 5px 5px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    color: #494949;
}

.Pesq_Tecnicos[type=text] {
    transition: width 0.4s ease-in-out;
    transition: box-shadow 0.4s ease-in-out;
  }
  
.Pesq_Tecnicos[type=text]:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgb(24,183,115, 0.75);
  }

#BtnSignUp_Tecnicos{
    font-family: sans-serif;
    display: flex;
    margin-right: 3.2%;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100px;
    height: 40px;
    color: white;
    font-weight: bolder;
    font-size: 18px;
    background-color: #18b773;
    border-color: #18b773;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
tableTecStyle

#BtnSignUp_Tecnicos:hover {

    box-shadow: 0 0px 15px rgb(24, 183, 115, 0.5);
  }
#BtnSignUp_Tecnicos:active {
    transform: translateY(2px);
}
  
.Body_Tecicos{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    
    width: 98%;
    margin-left: 10px;
    margin-right: 10px;
    height: 100%;
    
    
}
.tableTecStyle{
    padding: 5px;
    text-align: left;
    border-collapse: collapse;
    font-family: sans-serif;
    width: 96%;
    min-width: 400px;
    margin-top: 15px;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 40px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px 5px 0 0;
    
}

.tableTecStyle thead tr{
    background-color: #18B773;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
   
    
}

.tableTecStyle th,
.tableTecStyle td {
    padding: 12px 15px;
}

.tableTecStyle tbody tr {
    border-bottom: 1px solid #dddddd;
   
}

.tableTecStyle tbody tr:nth-of-type(even) {
    background-color: #f0fff0;
}
.tableTecStyle tbody tr:nth-of-type(odd) {
    background-color: #f6fcf9;
}

.tableTecStyle tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.perfil_Tecnicos{
    color: #197ED6;
}
.perfil_Tecnicos p{
    cursor: pointer;
    font-size: 16px;
    font-weight: bolder;
}
.perfil_Tecnicos p:hover{
    color: #004581;
}
.perfil_Tecnicos:hover{
    color: #004480;
}

.LogoImg{
    margin-left: 38vw;
    opacity: 0.1;
    height: 300px;
    width: 400px;
}

.EngrenagemTec{
    padding-left: 20px;
}

.dropdown-container-Tecnicos{
    position: relative;
    display: block;
    
  }

.dropdown-menu-Tecnicos {
   position: absolute;
   top: 100%;
   left: 0;
   z-index: 1;
   display: none;
   min-width: 120px;
   padding: 8px 0;
   margin: 2px 0 0;
   border: 1px solid #ccc;
   background-color: #fff;
   box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.175);
  
  
  
}


.usuario-button{
    background-color: transparent;
    border: none;
    color: #197ED6;
}


.description-box {
    width: 490px;
    height: 60px;
    border: 1px solid #ccc;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .description-content {
    padding: 1px;
    background-color: #f0f0f0; /* Color diferente para el espacio del texto */
    color: #333;
  }