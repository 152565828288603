.div{
    display: flex;
    
}  

 .div-createtec{
    display: flex;
 }

#BotaoVoltar_CreateTec{
    display: flex;
    position: absolute;
    margin-right: 91%;
    margin-top: 15px;
}
.body_CreateTec{
    font-family: sans-serif;
    background-color: #EEF5F9;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 110vh;
    
}

.card_CreateTec{
    margin-top: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 0 0;
    width: 400px;
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid green;

}

.card_CreateTec h1{
    margin-left: 15px;
}

.txtStyle_CreateTec{
    display: flex;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 15px;
}

.buttonStyle_CreateTec{
    font-family: sans-serif;
    display: flex;
    margin-left: 150px;
    margin-top: 15px ;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100px;
    height: 40px;
    color: white;
    font-weight: bolder;
    font-size: 18px;
    background-color: #18b773;
    border: 1px solid #18b773;
    cursor: pointer;
}

.buttonStyle_CreateTec:active{
    transform: translateY(2px);
}

#BtnSignUp:hover {
    border: 1px solid #cae6da;
  }

.inputPadrao_CreateTec{
    width: 90%;
    margin-left: 15px;
    margin-bottom: 5px;
    text-shadow: none;
    text-align: start;
    height: 35px;
    border-radius: 5px;
    border: 1px solid rgb(128, 128, 128);
    padding-left: 5px;
    font-size: 15px;
}

.cadastro_CreateTec{
    color: #00a35c;
}