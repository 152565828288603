.container-painel {
    display: flex;
}  
.wrapper {
    display: flex;


} 
.padding-EcoClub{
    margin-left: 18px;
}
.div_EcoClub{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: rgb(238, 245, 249);
    overflow: auto;
}

.nav_EcoClub{
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    width: 98;
    height: 55px;
    color: #197ED6;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}

.titulo_EcoClub{
    margin-left: 32px;
    font-size: 22px;
}

.navEndereco_EcoClub{
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: lighter; 
}

.navHome_EcoClub{
    margin-right: 15px;
    cursor: pointer;
}

.navAtual_EcoClub{
    color: #99abb4;
    margin-right: 20px;
}

.navAtual_EcoClub{
    color: #99abb4;
    margin-right: 20px;
}

.topCard_EcoClub{
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: baseline;

}

.Pesq_EcoClub{
    margin-left: 2.5%;
    width: 450px;
    height: 35px;
    border-width: 1px;
    border-radius: 5px;
    font-family: sans-serif;
    border-color: rgb(14, 14, 14, 0.2);
    box-sizing: border-box;
    background-image: url(../../components/img/searchIcon.svg);
    background-position: 5px 5px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    color: #494949;
}

.Pesq_EcoClub[type=text] {
    transition: width 0.4s ease-in-out;
    transition: box-shadow 0.4s ease-in-out;
  }
  
.Pesq_EcoClub[type=text]:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgb(24,183,115, 0.75);
  }

#BtnSignUp_EcoClub{
    margin-left: 48%;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 3.2%;
    border-radius: 5px;
    width: 145px;
    padding: 2px;
    height: 50px;
    color: white;
    font-weight: bolder;
    font-size: 18px;
    background-color: #18b773;
    border-color: #18b773;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#BtnPatrocinador_EcoClub{
    margin-left: 2%;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 3.2%;
    border-radius: 5px;
    width: 145px;
    padding: 2px;
    height: 50px;
    color: white;
    font-weight: bolder;
    font-size: 18px;
    background-color: #18b773;
    border-color: #18b773;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


#BtnSignUp_EcoClub:hover {
    box-shadow: 0 0px 15px rgb(24, 183, 115, 0.5);
}
#BtnPatrocinador_EcoClub:hover {
    box-shadow: 0 0px 15px rgb(24, 183, 115, 0.5);
}

.Body_EcoClub{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    
    width: 98%;
    margin-left: 10px;
    margin-right: 10px;
    height: 100%;
    
    
}
.tableStyle_EcoClub{
    padding: 5px;
    text-align: left;
    border-collapse: collapse;
    font-family: sans-serif;
    width: 96%;
    min-width: 400px;
    margin-top: 15px;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 40px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px 5px 0 0;
    
}

.tableStyle_EcoClub thead tr{
    background-color: #18B773;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
}

.tableStyle_EcoClub th,
.tableStyle_EcoClub td {
    padding: 12px 15px;
}

.tableStyle_EcoClub tbody tr {
    border-bottom: 1px solid #dddddd;
}

.tableStyle_EcoClub tbody tr:nth-of-type(even) {
    background-color: #f0fff0;
}
.tableStyle_EcoClub tbody tr:nth-of-type(odd) {
    background-color: #f6fcf9;
}

.tableStyle_EcoClub tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.perfil_EcoClub{
    color: #197ED6;
    cursor: pointer;
    
}

.perfil_EcoClub:hover{
    color: #004480;
}

.LogoImg{
    margin-left: 38vw;
    opacity: 0.1;
    height: 300px;
    width: 400px;
}

.Engrenagem_EcoClub_EcoClub{
    padding-left: 20px;
}

.dropdown-container_EcoClub{
    position: relative;
    display: block;
    margin-left: 18px;
  }

.dropdown-menu-EcoClub {
   position: absolute;
   top: 100%;
   left: 0;
   z-index: 1;
   display: none;
   min-width: 120px;
   padding: 8px 0;
   margin: 2px 0 0;
   border: 1px solid #ccc;
   background-color: #fff;
   box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.175);
}
  
.checkboxPadrao_EcoClub{
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 28px;
   
}