.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.error-code {
    font-size: 6em;
    font-weight: bold;
}

.error-message {
    font-size: 1.5em;
}

.error-home-link {
    margin-top: 20px;
    display: inline-block;
    background-color: #18b773;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.error-home-link:hover {
    background-color: #16905b;
}
