.status-andamento {
  font-weight: bold;
  color: red;
}

.status-finalizado {
  font-weight: bold;
  color: rgb(4, 119, 4);
}

.status-aguardando-tecnico{
  font-weight: bold;
  color: rgb(252, 160, 21);
}


.container-painel {
    display: flex;
  
  }
  
  .wrapper {
    display: flex;
  
  
  }
  
  .padding-chamadas {
    margin-left: 18px;
  }
  
  .div-chamadas {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: rgb(238, 245, 249);
    overflow: auto;
  }
  .nome-Maquinas:hover{
    color: #004480;
  
  }
  
  .nav_chamadas{
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    width: 98;
    height: 55px;
    color: #197ED6;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .navEndereco_chamadas{
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: lighter;
  }
  
  .nome-Maquinas-chamadas{
    color: #197ED6;
    cursor: pointer;
  }
  
  .titulo_chamadas{
    margin-left: 32px;
    font-size: 22px;
  }
  .home-redirect-chamadas{
    color: #197ED6;
    
  }
  
  .navHome_chamadas{
    margin-right: 15px;
    cursor: pointer;
  }
  
  .navAtual_chamadas{
    color: #99abb4;
    margin-right: 20px;
  }
  
  
  .Bodychamadas{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    width: 98%;
    margin-left: 10px;
    margin-right: 10px;
    height: 100%;
  }
  
  .pListaDeChamadas{
    margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .pListaDeChamadas p{
    font-family: sans-serif;
      font-weight: bold;
      font-size: 25px;
      color: #2c2c2c;
  }
  
  .topCardChamadas{
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  }
  
  .PesqChamadas{
    margin-left: 2.5%;
    width: 450px;
    height: 35px;
    border-width: 1px;
    border-radius: 5px;
    font-family: sans-serif;
    border-color: rgb(14, 14, 14, 0.2);
    box-sizing: border-box;
    background-image: url(../../components/img/searchIcon.svg);
    background-position: 5px 5px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    color: #494949;
  }
  
  .PesqChamadas[type=text] {
    transition: width 0.4s ease-in-out;
    transition: box-shadow 0.4s ease-in-out;
  }
  
  .PesqChamadas[type=text]:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgb(24,183,115, 0.75);
  }
  
  .EngrenagemChamadas{
    padding-left: 20px;
  }
  
  .TablechamadasStyle{
    padding: 5px;
    text-align: left;
    border-collapse: collapse;
    font-family: sans-serif;
    width: 96%;
    min-width: 400px;
    margin-top: 15px;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 40px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px 5px 0 0;
  }
  
  .TablechamadasStyle thead tr{
    background-color: #18B773;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  
  .TablechamadasStyle th,
  .TablechamadasStyle td{
    padding: 12px 15px;
  }
  
  .TablechamadasStyle tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .TablechamadasStyle tbody tr:nth-of-type(even) {
    background-color: #f0fff0;
  }
  .TablechamadasStyle tbody tr:nth-of-type(odd) {
    background-color: #f6fcf9;
  }
  
  .TablechamadasStyle tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
  }
  
  .dropbtn-chamadas {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
  }
  
  
  .dropdown-menu-chamadas {
    top: 100%;
    right: 2%;
    z-index: 1;
    display: none;
    min-width: 120px;
    padding: 8px 0;
    margin: 2px 0 0;
    border: 1px solid #ccc;
    background-color: #fff;
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    color: rgba(255, 255, 255, 0.342);
  }
  .show {
    display: block;
  }
  
  .dropdown-item-chamadas {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212922;
    text-align: left;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #16181b;
    background-color: #f8f9fa;
  }
  .icon-settings {
    position: relative;
    top: 2px;
  }
  .dropdown.show .dropdown-content {
    display: block;
  }
  .dropdown-container-chamadas {
    position: relative;
  }
  .dropdown-interno {
    position: relative;
  }
  
  .button-atender{
    padding-bottom: 5px;
    }
    
    .button-atender-chamadas button{
      color: white;
      font-weight: bold;
      border-radius: 3px;
      padding: 2px;
      border: none;
      cursor: pointer;
      margin-bottom: 5px;
    }
    
    .button-atender-chamadas button:hover{
      background-color: #45bda5cc;
    }
    .button-encerrar-chamadas button:hover{
      background-color: #ff5f5f;
    }
  
    .atender-chamadas{
      background-color: #009879;
      width: 70px;
    }
  
    .encerrar-chamadas{
      color: white;
      font-weight: bold;
      background-color: #ff0040f8;
      width: 70px;
      border: none;
    }
    
    .button-encerrar-chamadas button{
      border-radius: 3px;
      padding: 2px;
      border-width: 1px;
      cursor: pointer;
    }
    
    .dropdown-chamadas {
      align-items: center;
      position: absolute;
      right: 0;
      left: auto;
      font-size: 0.875rem;
      z-index: 9z999;
      min-width: 2rem;
      padding: 0.5rem 0;
      list-style: none;
      background-color: #d3cece;
      border-radius: 0.1rem;
      display: none;
    }
    
    .menus-chamadas {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      list-style: none;
    }
    
    .menu-items-chamadas {
      list-style-type: none;
      align-items: center;
      font-size: 14px;
    }
    
    .menu-items-chamadas button {
      display: flex;
      align-items: center;
      font-size: inherit;
      cursor: pointer;
      width: 30px;
      height: 100%;
      border-radius: 4px;
    
    }
    
    .dropdown-chamadas.show {
      display: flex;
      flex-direction: column;
    }
    
    #botoes-chamada{
      display: flex;
      flex-direction: column;
      list-style: none;
    }
  
    .tbodyChamadas select:focus {
      border-color: #0073ff; /* Cor de destaque personalizada */
      box-shadow: 0 0 5px rgba(0, 255, 162, 0.5); /* Efeito de sombra suave */
    }
    .SelectResponsavel{  
      padding: 5px;
      border: 1px solid #606060;
      border-radius: 3px;
      font-size: 22px;
      width: 180px; /* Ajuste a largura conforme necessário */
      outline: none; /* Remove a borda de foco padrão */
    }
  
  .btnAtenderChamadasTecnicos{
    border: none;
    margin-top: 10px;
    background-color: #009879;
    color: white;
    padding: 10px;
    width: 100px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    border-radius: 2px;
  
  }
  
  .btnAtenderChamadasTecnicos:active {
    transform: translateY(2px);
  }
  
  .btnAtenderChamadasTecnicos:hover{
    background-color: #00876c;
    cursor: pointer;
  }
  .btnEncerrarChamadasTecnicos{
    border: none;
    margin-top: 10px;
    background-color: #009879;
    color: white;
    padding: 10px;
    width: 100px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    border-radius: 2px;
  
  }
  
  .btnEncerrarChamadasTecnicos:active {
    transform: translateY(2px);
  }
  
  .btnEncerrarChamadasTecnicos:hover{
    background-color: #00876c;
    cursor: pointer;
  }
  
 